import { motion } from "framer-motion"

const TransitionScreen = () => {
  return (
    <>
      {/* <motion.div 
        className="loading-screen slide-in"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1]}}>
      </motion.div> */}
      <motion.div 
        className="loading-screen slide-out"
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 3, ease: [0.22, 1, 0.36, 1]}}>
      </motion.div>
    </>
  )
}

export default TransitionScreen
