import { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

export default function RevealFramer({children}) {
  const ref= useRef(null);
  const isInView = useInView(ref, { once: true });

  const controls = useAnimation();

  useEffect(() => {
    if (isInView){
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <div ref={ref} className="animate-text">
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 }
        }}
        initial="hidden"
        animate={controls}
        transition={{duration: 0.5, delay: 0.3}}>
        {children}
      </motion.div>
    </div>
  )
}
